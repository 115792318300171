import React from 'react';
import './loading.scss';
import LoadingSpinner from '../../components/misc/loadingSpinner';

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading-content">
        <LoadingSpinner />
      </div>
    </div>
  );
};

export default Loading;
