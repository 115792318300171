import React from 'react';
import './loadingSpinner.scss';

const LoadingSpinner = () => {
  return (
    <div className="loadingSpinner__container">
      <div className="loadingSpinner__group">
        <span className="loadingSpinner__left" />
        <span className="loadingSpinner__right" />
      </div>

      <div className="loadingSpinner__group">
        <span className="loadingSpinner__left-top" />
        <span className="loadingSpinner__right-bottom" />
      </div>

      <div className="loadingSpinner__group">
        <span className="loadingSpinner__left-bottom" />
        <span className="loadingSpinner__right-top" />
      </div>
    </div>
  );
};

export default LoadingSpinner;
