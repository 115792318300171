import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './app.scss';
// @ts-ignore
import { CookieBanner } from '@palmabit/react-cookie-law';
import { useTranslation } from 'react-i18next';

const Home = React.lazy(() => import('./compositions/Home/Home'));
const Impressum = React.lazy(() => import('./compositions/Impressum/Impressum'));
const Privacy = React.lazy(() => import('./compositions/Privacy/Privacy'));
const Cookies = React.lazy(() => import('./compositions/Cookies/Cookies'));
const Download = React.lazy(() => import('./compositions/Download/Download'));
const ScrollToTop = React.lazy(() => import('./components/layout/ScrollToTop'));
const Error404 = React.lazy(() => import('./compositions/Error/Error404'));

function App() {
  const { t } = useTranslation('cookies');

  const routes = [
    {
      path: '/',
      component: Home,
    },
    {
      path: '/impressum',
      component: Impressum,
    },
    {
      path: '/privacy',
      component: Privacy,
    },
    {
      path: '/cookies',
      component: Cookies,
    },
    {
      path: '/download',
      component: Download,
    },
    {
      path: '*',
      component: Error404,
    },
  ];

  return (
    <Router>
      <CookieBanner
        className="cookie-banner"
        message={t('cookies:cookies_banner.message')}
        acceptButtonText={t('cookies:cookies_banner.acceptButtonText')}
        privacyPolicyLinkText={t('cookies:cookies_banner.privacyPolicyLinkText')}
        necessaryOptionText={t('cookies:cookies_banner.necessaryOptionText')}
        preferencesOptionText={t('cookies:cookies_banner.preferencesOptionText')}
        statisticsOptionText={t('cookies:cookies_banner.statisticsOptionText')}
        marketingOptionText={t('cookies:cookies_banner.marketingOptionText')}
        showDeclineButton={false}
        onAccept={() => {}}
        onAcceptPreferences={() => {}}
        onAcceptStatistics={() => {}}
        onAcceptMarketing={() => {}}
      />
      <ScrollToTop />
      <Switch>
        {routes.map((route, i) => {
          return <Route exact path={route.path} key={i} component={route.component} />;
        })}
      </Switch>
    </Router>
  );
}

export default App;
